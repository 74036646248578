<template>
  <BlokkliProvider v-bind="blokkli">
    <Container>
      <div class="lg:w-8/12 node">
        <SplitTitle
          v-slot="{ top, bottom }"
          :title="title"
          :bold-count="titleWords"
        >
          <ContentHeader
            :title="top"
            :title-light="bottom"
            :lead="summary"
            :icon="icon"
          >
            <div
              v-if="body"
              class="content mt-20 md:mt-30 lg:mt-50"
              v-html="body"
            />
          </ContentHeader>
        </SplitTitle>
      </div>
      <slot name="paragraphs-before"></slot>
    </Container>

    <slot name="header-after"></slot>

    <BlokkliField
      v-slot="{ items }"
      :list="paragraphs"
      name="field_paragraphs"
      non-empty-class="container is-content mx-auto px-20"
    >
      <TabNavigation
        v-if="showAnchorNavigation"
        :tabs="getTabs(items)"
        class="mb-20 md:mb-30 lg:mb-60"
      />
    </BlokkliField>

    <Container>
      <BlokkliField
        :list="paragraphsFooter"
        non-empty-class="is-footer"
        name="field_paragraphs_footer"
      />
      <div
        v-if="paywall?.showPaywall && siteName === 'pharmasuisse'"
        class="lg:w-8/12"
      >
        <Paywall v-bind="paywall" />
      </div>
    </Container>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import {
  MediaIconFragment,
  NodePageFragment,
  ParagraphSectionTitleFragment,
} from '#graphql-operations'
import { falsy } from '~/helpers/type'
import { slugify } from '~/helpers/slugify'
import { FieldListItemTyped } from '#blokkli/generated-types'

const { name: siteName } = useCurrentSite()

const props = defineProps<{
  uuid?: string
  canEdit?: boolean
  title?: string
  titleWords?: number
  body?: string
  summary?: string
  icon?: MediaIconFragment
  paywall?: NodePageFragment['paywall']
  showAnchorNavigation?: boolean
  paragraphs?: NodePageFragment['paragraphs']
  paragraphsFooter?: NodePageFragment['paragraphsFooter']
}>()

function getTabs(items: FieldListItemTyped[]) {
  return items
    .filter((v) => {
      if (v.bundle === 'section_title') {
        const value = v.options?.showInNavigation
        return value === '1'
      }
    })
    .map((v) => {
      const paragraph = v.props as ParagraphSectionTitleFragment
      const label = paragraph.anchor || paragraph.title
      if (label) {
        return {
          label,
          id: slugify(label),
        }
      }

      return null
    })
    .filter(falsy)
}

const blokkli = buildBlokkliProps(props)
</script>

<style lang="postcss"></style>
