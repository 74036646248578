<template>
  <nav v-if="tabs.length" ref="anchorNav" class="tabnav">
    <div class="tabnav-inner">
      <ClientOnly>
        <button v-if="prevVisible" @click="scrollList(-200)">
          <SpriteSymbol name="chevron" class="w-15 h-15 rotate-90" />
        </button>
      </ClientOnly>
      <div
        ref="elTabs"
        class="tabnav-tabs"
        :class="{
          'has-gradient': showGradient,
        }"
      >
        <ul ref="elTabsList" class="tabnav-list">
          <li
            v-for="tab in tabs"
            :key="tab.id"
            ref="tabItems"
            :class="{ 'is-active': tab.id === active }"
          >
            <a :href="'#' + tab.id" @click.prevent="onClick(tab.id)">
              {{ tab.label }}
            </a>
          </li>
        </ul>
      </div>
      <ClientOnly>
        <button v-if="nextVisible" @click="scrollList(200)">
          <SpriteSymbol name="chevron" class="w-15 h-15 -rotate-90" />
        </button>
      </ClientOnly>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { falsy } from '~/helpers/type'

interface TabPosition {
  id: string
  y: number
}

export interface TabItem {
  id: string
  label: string
}

const props = defineProps<{
  tabs: TabItem[]
}>()

const route = useRoute()
const router = useRouter()

const positions = ref<TabPosition[]>([])
const raf = ref<any>(null)

const active = ref('')
const lastScroll = ref(0)
const tabsListScroll = ref(0)

const isScrollingTo = ref(false)
const scrollToTimeout = ref<any>(null)
const viewportWidth = ref(768)

const containerWidth = ref(0)
const scrollWidth = ref(0)

const tabItems = ref<HTMLLIElement[]>([])
const elTabs = ref<HTMLDivElement | null>(null)
const elTabsList = ref<HTMLDivElement | null>(null)

const itemsAreOverflowing = computed(() => {
  return scrollWidth.value > containerWidth.value
})

const prevVisible = computed(() => {
  return itemsAreOverflowing.value && tabsListScroll.value > 0
})

const nextVisible = computed(() => {
  return (
    itemsAreOverflowing.value &&
    scrollWidth.value - tabsListScroll.value - containerWidth.value > 0
  )
})

const showGradient = computed(() => {
  return (
    itemsAreOverflowing.value &&
    active.value !== props.tabs[props.tabs.length - 1]?.id
  )
})

function getCoords(elem: HTMLDivElement) {
  // crossbrowser version
  const box = elem.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

function scrollToSection() {
  const section = route.query?.section
  if (section && window.scrollY === 0) {
    const target = positions.value.find((v) => v.id === section)
    if (target) {
      active.value = target.id
      requestAnimationFrame(() => {
        window.scrollTo({
          top: target.y - 100,
          left: 0,
        })
        lastScroll.value = window.scrollY
      })
    }
  }
}

function scrollList(amount: number) {
  if (!elTabsList.value) {
    return
  }

  elTabsList.value.scrollBy(amount, 0)
}

function setPositions() {
  const newPositions = props.tabs
    .map((v) => {
      const el = getTabContentElement(v.id)
      if (el) {
        const coords = getCoords(el as any)
        return { id: v.id, y: coords.top }
      }
      return null
    })
    .filter(falsy)
    .sort((a, b) => {
      return a.y > b.y ? 1 : -1
    })

  positions.value = newPositions
  containerWidth.value = elTabs.value?.scrollWidth || 0
  scrollWidth.value = elTabsList.value?.scrollWidth || 0
}

function getTabContentElement(id: string): HTMLDivElement | null {
  const el = document.querySelector('#' + id)
  if (el) {
    return el as HTMLDivElement
  }
  return null
}

function loop() {
  setPositions()
  if (lastScroll.value !== window.scrollY && !isScrollingTo.value) {
    findActive()
    lastScroll.value = window.scrollY
  }
  viewportWidth.value = window.innerWidth
  tabsListScroll.value = elTabsList.value?.scrollLeft || 0
  containerWidth.value = elTabs.value?.scrollWidth || 0
  scrollWidth.value = elTabsList.value?.scrollWidth || 0
  raf.value = requestAnimationFrame(loop)
}

function findActive() {
  active.value = findActiveMobile(window.scrollY)
}

function findActiveMobile(scrollY: number) {
  for (let i = positions.value.length - 1; i >= 0; i--) {
    const item = positions.value[i]
    if (scrollY + 200 > item.y) {
      return item.id
    }
  }

  return positions.value[0]?.id
}

function onClick(id: string) {
  const target = positions.value.find((v) => v.id === id)
  if (target) {
    active.value = target.id
    setPositions()
    clearTimeout(scrollToTimeout.value)
    isScrollingTo.value = true
    window.scrollTo({
      top: target.y - 100,
      left: 0,
      behavior: 'smooth',
    })
    scrollToTimeout.value = setTimeout(() => {
      lastScroll.value = window.scrollY
      isScrollingTo.value = false
    }, 900)
    router.push({ query: { section: id } })
  }
}

watch(active, (id) => {
  const activeIndex = props.tabs.findIndex((v) => v.id === id)
  if (activeIndex < 0 || !elTabsList.value || !elTabs.value) {
    return
  }
  const item = tabItems.value[activeIndex]

  const containerScrollLeft = elTabsList.value.scrollLeft
  const positionInContainer = item.offsetLeft
  const itemPositionLeft = positionInContainer - containerScrollLeft
  if (itemPositionLeft < 0) {
    const target = containerScrollLeft + itemPositionLeft
    elTabsList.value.scrollTo(target, 0)
    return
  }

  const itemWidth = item.scrollWidth
  const cutoffRight = containerWidth.value - (itemPositionLeft + itemWidth)
  if (cutoffRight < 0) {
    const target = Math.abs(cutoffRight) + containerScrollLeft
    elTabsList.value.scrollTo(target, 0)
  }
})

watch(viewportWidth, setPositions)
watch(() => props.tabs, setPositions)

onMounted(() => {
  loop()
  setPositions()
  findActive()
  setTimeout(() => {
    scrollToSection()
  }, 0)
})

onBeforeUnmount(() => {
  cancelAnimationFrame(raf.value)
})
</script>

<style lang="postcss">
.tabnav-inner {
  @apply overflow-hidden relative;
}
.tabnav {
  button {
    @apply absolute top-0 h-full z-40 w-40 flex items-center justify-start text-gray-500 hover:text-green-alt mobile-only:hidden;
    &:first-child {
      @apply left-0 bg-gradient-to-l from-white/0 to-white;
    }
    &:last-child {
      @apply right-0 justify-end;
    }
  }
}
</style>
